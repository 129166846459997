<template>
    <div v-if="dataDetail" @click="liveJumpDetail(item.live_id)" class="live-item mb20" :style="{ width: type1 == 'horizontal' ? '100%' : initWidth }" :class="{ 'horizontal-layout': type1 == 'horizontal' }">
        <div class="live-item-image">
            <img class="live-item-image-c" :style="{ width: initWidth, height: height ? height : (parseInt(initWidth) / 16 * 9 + 'px') }" :src="dataDetail.live_banner | urlFilter(250)" @error="setDefaultImage" alt="" />
            <img src="~@/assets/images/play-icon.png" class="play-icon">
            <div v-if="dataDetail.status == 0" class="count-time">{{ countdownTime }}</div>
        </div>
        <div class="pt10 pl10 pr10 pb10 t-l live-item-right">
            <div :title="dataDetail.title" class="fs16 color333 strong row-2 lir-title">{{ dataDetail.title }}</div>
            <div class="flex mt10 y-bottom">
                <div :class="{ 'w115': type1 == 'vertical' }">
                    <div class="color999 fs14"><i class="med med-31shijian"></i>{{
                        dataDetail.start_time &&
                            dataDetail.start_time.substr(0, 10)
                    }}</div>
                    <div :class="{ 'w115': type1 == 'horizontal' }" class="color999 fs14 row-1"><i class="med med--didian"></i>{{ dataDetail.hall_name }}</div>
                </div>
                <!-- 预约直播 -->
                <div v-if="dataDetail.status == 0" @click.stop="appointLive">
                    <div class="live-btn live-start" v-if='dataDetail.applet_remind == 0'>预约直播</div>
                    <div class="live-btn lived" v-if='dataDetail.applet_remind == 1'>已预约</div>
                </div>
                <!-- 直播中 -->
                <div v-else-if="dataDetail.status == 1" class="live-btn living live-status">
                    <b></b><b></b><b></b><b></b>直播中
                </div>
                <!-- 观看回放 -->
                <div v-else class="live-btn lived">观看回放</div>
            </div>
        </div>
    </div>
</template>

<script>
import { dateFormat, UTCToLocalTimeString } from '@/utils/dateFormat.js';
import { liveJumpDetail } from '@/utils/jumpPageMethods';
import storage from 'store';
export default {
    name: "live-item",
    props: {
        type: {
            type: String,
            default: 'vertical',
        },
        width: {
            type: String,
            default: '245px',
        },
        height: {
            type: [Number, String],
            default: 0,
        },
        item: {
            type: Object,
            default: () => {
                // id:1,
                // img:'',
                // title:',
                // url:'',  
                // start_date:'',
                // end_date:'',
                // address:'',
            },
        },
    },
    data() {
        return {
            initWidth: 0,
            dataDetail: null,
            countdownTime: '00:00:00',
            type1: 'vertical',
        }
    },
    watch: {
        item(newVal, oldVal) {
            this.dataDetail = newVal
            this.setLiveStatus();
            !this.dataDetail.timer && (this.dataDetail.timer = setInterval(this.setLiveStatus, 1000))
        }
    },
    methods: {
        liveJumpDetail,
        // 判断直播状态
        setLiveStatus() {
            if (!this.dataDetail.start_time || !this.dataDetail.end_time) {
                return
            }
            let nowTime = UTCToLocalTimeString(new Date()).getTime();
            let startTime = this.dataDetail.start_time.replace(/-/g, '/');
            let endTime = this.dataDetail.end_time.replace(/-/g, '/');
            endTime = endTime.slice(0, 10) + ' 23:59:59'
            startTime = +(new Date(startTime));
            endTime = +(new Date(endTime));
            if (startTime > nowTime) {
                // 未开始
                this.$set(this.dataDetail, 'status', 0);
                // 获取倒计时
                this.countdownTime = dateFormat(startTime - nowTime);
            } else if (nowTime > startTime && endTime > nowTime && this.dataDetail.is_live_in == '1') {
                // 直播中
                this.$set(this.dataDetail, 'status', 1);
                clearInterval(this.dataDetail.timer);
            } else {
                // 已结束
                this.$set(this.dataDetail, 'status', 2);
                clearInterval(this.dataDetail.timer);
            }
        },
        setDefaultImage(e) {
            e.target.src = 'https://files.sciconf.cn/asset/online-meeting/images/live-default-banner.jpg'
        },
        appointLive() {
            let { live_id } = this.dataDetail
            this.request.post('AppletAppointment', {
                pages_url: "/live-pages/live-play/live-play?id=" + live_id,
                type: 1,
                type_id: live_id,
                tj_token: storage.get('tid')
            }).then(res => {
                this.dataDetail.applet_remind = 1
            })
        }
    },
    mounted() {
        let clientWidth = document.body.clientWidth
        this.dataDetail = this.item;
        this.type1 = this.type

        let initWidth = (this.type1 == 'horizontal' && this.width == '245px') ? '185px' : this.width;

        if (clientWidth > 550 && clientWidth <= 768 && this.type1 != 'horizontal') {
            initWidth = (clientWidth - 40) / 2 + 'px'
        }
        if (clientWidth <= 550) {
            this.type1 = 'horizontal'
            initWidth = (clientWidth) / 2.7 + 'px'
        }
        this.initWidth = initWidth
        this.setLiveStatus();
        !this.dataDetail.timer && (this.dataDetail.timer = setInterval(this.setLiveStatus, 1000))
    }
};
</script>

<style scoped lang="less">
.live-item {
    background: #f9f9f9;
    display: block;
    cursor: pointer;

    .w115 {
        width: 110px;
    }

    &-image {
        position: relative;

        .play-icon {
            width: 45px;
            opacity: 0.6;
            color: #131415;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -22px;
            margin-top: -22px;
        }
    }

    .count-time {
        height: 23px;
        line-height: 23px;
        font-size: 12px;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        position: absolute;
        right: 5px;
        bottom: 5px;
        padding: 0 12px;
    }

    .lir-title {
        min-height: 50px;
    }

    .live-btn {
        padding: 6px 13px;
        border-radius: 35px;
        line-height: 20px;
        height: 32px;
        font-size: 12px;
        color: #fff;
    }

    .live-start {
        background: linear-gradient(to right, #f5bf58, #f2873b);
    }

    .living {
        background: linear-gradient(to right, #5f96e6, #1e51c9);
    }

    .lived {
        background: #cccccc;
    }
}

.horizontal-layout {
    display: flex;
    background-color: #fff;

    .live-item-right {
        padding: 0 0 0 10px;
        flex: 1;
    }
}
@media screen and (max-width: 768px) {
    .live-item {
        &-image {
            &-c {
                width: 130px !important;
                height: 73px !important;
            }
            .play-icon{
                width:30px;
                font-size:14px;
                margin-left:-15px;
                margin-top:-15px;
            }
        }
        .lir-title {
            min-height: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
    }
}


@import url("~@/assets/css/live-icon.less");
</style>
