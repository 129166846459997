<template>
    <router-link :to="{ path: item.url,query:{id:item.id}}" class="vertical-media-item flex-column">
      <div :style="{width:width,height:parseInt(width)/16*9+'px'}" class="v-item-img">
        <img
          :src="item.img"
          alt=""
        />
        <div class="row-1 colorfff fs12 v-img-title fs12 pl10 pr10 t-l">{{item.title}}</div>
      </div>
    </router-link>
</template>

<script>
export default {
    name: "survey",
    props: {
        width: {
            type: String,
            default: '320px',
        },
        height: {
            type: [Number, String],
            default: 0,
        },
        item: {
            type: Object,
            defalut: () => {
              //   id:1,
              //   img:'',
              //   title:',
              //   url:'',  
            },
        },
    },
};
</script>

<style scoped lang="less">
.vertical-media-item {
    color: #333 !important;
    .v-item-img {
        transition: all 0.3s linear;
        position: relative;
        .v-img-title {
            position: absolute;
            bottom: 0;
            background: rgba(0, 0, 0, 0.3);
            width: 100%;
            height: 35px;
            line-height: 35px;
            box-sizing: border-box;
        }
        &:hover {
            transform: scale(1.02);
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
