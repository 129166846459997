<template>
    <router-link class="journal-item flex x-left mb20" :to="{path:path,query:{id:journalItem.jou_id,listid:journalItem.id ? journalItem.id : ''}}">
        <img :style="{width:width,height:height}" :src="journalItem.img_url | urlFilter(250)" alt="">
        <div class="flex flex-column ml20"  :class="{'maxw':type == 2}" :style="{width:'calc(100% - 20px - '+width+')' }">
            <div class="journal-item-title t-l flex-column x-between">
                <span :title="journalItem.cn_name" class="strong color333 row-2" :style="{fontSize:fontSize}">{{journalItem.cn_name}}</span>
                <span class="color999 fs14 row-3" v-if="journalItem.desc">{{journalItem.desc}}</span>
            </div>
            <div class="journal-item-titlet t-l flex-column x-between">
                <span class="fs14 color999 row-1" v-if="journalItem.org">主办单位：<span class="color333">{{journalItem.org}}</span></span>
                <span class="fs14 color999 row-1" v-if="journalItem.cn_code">国内期刊：<span class="color333">{{journalItem.cn_code}}</span></span>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'journal-item',
    props: {
        path: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: '160px',
        },
        height: {
            type: String,
            default: '200px',
        },
        type: {
            type: String,
            default: '1',
        },
        fontSize: {
            type: [Number, String],
            default: '18px',
        },
        journalItem:{
            type:Object,
            default:{}
        }
    },
    mounted(){
        
    },
    methods:{
        
    },
}
</script>

<style scoped lang="less">
.journal-item{
    .maxw{
        max-width: 185px;
    }
}
@media screen and (min-width:551px) and (max-width:768px){
    .journal-item{
    .maxw{
        max-width: 100%!important;
    }
}
}
</style>
