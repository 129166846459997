module.exports = {
    // 根据时间戳计算具体持续时间,天 时 分 秒
    dateFormat: function (datetime) {
        let totalTime = datetime;
        if (totalTime <= 0) {
            return '0,00:00:00';
        }
        let days = num(parseInt(totalTime / (1000 * 60 * 60 * 24)));
        let hours = num(parseInt((totalTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        let minutes = num(parseInt((totalTime % (1000 * 60 * 60)) / (1000 * 60)));
        let seconds = num(parseInt((totalTime % (1000 * 60)) / 1000));

        function num(val) {
            return val >= 10 ? val : '0' + val;
        }
        return days + ',' + hours + ':' + minutes + ':' + seconds;
    },

    /* 获取北京时间 */
    UTCToLocalTimeString: function (d) {
        var timeOffsetInHours = (new Date().getTimezoneOffset() / 60) + 8; //这里的 8是时区，东八区为北京时间，PS：东区为正数 西区为负数
        d.setHours(d.getHours() + timeOffsetInHours);
        return d;
    },
}