<template>
    <div class="album-box flex x-left flex-wrap">
        <router-link :class="['album-item t-l strong color333 row-1', { 'mr0' : (index + 1) % lineCount == 0 }]" v-for="(item,index) in dataList" :key="index" :to="{ path: '/organization/org-album-detail',query:{id:item.id,title:item.cn_title}}">
            <div class="album-image flex flex-wrap mb10" v-if="item.images.length == 3">
                <img class="album-image-img" :src="items.image_url | urlFilter(750)" alt="" v-for="(items,indexs) in item.images" :key="indexs" />
            </div>
            <div class="album-image2 flex flex-wrap mb10" v-if="item.images.length == 2">
                <img class="album-image2-img" :src="items.image_url | urlFilter(750)" alt="" v-for="(items,indexs) in item.images" :key="indexs" />
            </div>
            <div class="album-image2 flex flex-wrap mb10" v-if="item.images.length == 1">
                <img class="album-image1-img" :src="items.image_url | urlFilter(750)" alt="" v-for="(items,indexs) in item.images" :key="indexs" />
            </div>
            {{item.cn_title}}
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "courser-list-item",
        props : {
            dataList : {
                type : Array,
                default : () => []
            },
            lineCount : {
                type : [Number,String],
                default : 2
            },//几个一行，最后一个需要设置marginRight 0
        },
        created(){
            
        },
        methods:{
            
        }
    }
</script>

<style scoped lang="less">
    .album-item{
        width: 376px;
        height: 244px;
        margin-right: 18px;
        &.mr0{
            margin-right: 0;
        }
        .album-image{
            height: 212px;
            &-img{
                width: 188px;
                height: 106px;
                background: #ccc;
            }
            &-img:nth-child(3){
                width: 376px;
                height: 106px;
                object-fit: cover;
                object-position: center;
            }
        }
        .album-image2{
            width: 376px;
            &-img{
                width: 376px;
                height: 106px;
                object-fit: cover;
                object-position: top;
            }
            .album-image1-img{
                width: 100%;
                height: 212px;
                object-fit: cover;
                object-position: top;
            }
        }
    }
    @media screen and (max-width:768px){
        .album-item{
            width: 48%;
            height: 150px;
            margin-right: 2%;
            &.mr0{
                margin-right: 0;
            }
            .album-image{
                height: 100%;
                height: 120px;
                &-img{
                    width: 50%;
                    height: 60px;
                    object-fit: cover;
                    object-position: center;
                    background: #ccc;
                }
                &-img:nth-child(3){
                    width: 100%;
                    height: 60px;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .album-image2{
                width: 100%;
                height: 100px;
                &-img{
                    width: 50%;
                    height: 100px;
                    object-fit: cover;
                    object-position: top;
                }
                .album-image1-img{
                    width: 100%;
                    height: 100px;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }
    }
</style>
