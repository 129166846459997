<template>
    <div @click="handleNavigateEvent(item)" class="image-title mb20">
        <div class="inner-image-title flex x-between">
            <div class="image-left" ref="box" :style="{width:width1,height:height?height:(parseInt(width1)/16*9+'px')}">
                <img :src="item.img | urlFilter(250)" alt="" />
            </div>
            <div class="image-right-title ml20 t-l flex-item flex flex-column x-between">
                <div>
                    <div v-if="item.title" :title="item.title" class="color333 strong fs16 course-detail-title" :class="type == 'layoutRight' ? 'row-2' : 'row-1'">{{ item.title }}</div>
                    <div v-if="item.desc" class="image-right-p color999 fs14 row-2">{{ item.desc }}</div>
                    <slot class="image-right-p" v-else name="desc"></slot>
                </div>
                <slot name="bottom"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
export default {
    name: 'across-media-item',
    props: {
        width: {
            type: String,
            default: '185px',
        },
        height: {
            type: [Number, String],
            default: 0,
        },
        type: {
            type: String,
            default: '',
        },
        gap: {
            type: String,
            default: '20',
        },
        item: {
            type: Object,
            default: () => {
                // id: 1,
                // img: '',
                // title: '',
                // url: '',
                // desc:'',
            }
        }
    },
    data() {
        return {
            width1: '',
        }
    },
    mounted() {
        // 取屏幕宽度的1/3设置移动端图片宽度
        this.width1 = this.width
        let clientWidth = document.body.clientWidth
        if (clientWidth <= 550) {
            this.width1 = clientWidth / 3 + 'px'
        }
    },
    methods: {
        handleNavigateEvent(item) {
            if (item.url) {
                router.push({
                    path: item.url,
                })
            } else {
                this.$emit('navigateTo', item);
            }
        }
    },
}
</script>

<style scoped lang="less">
.image-title {
    cursor: pointer;
    // border-top: 1px solid #f1f1f1;
    &-text {
        color: @theme;
        border-top: 2px solid @theme;
        line-height: 1;
        display: inline-block;
        margin-top: -2px;
        font-weight: bold;
    }
    .image-left {
        background: #ccc;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    .image-right-title {
        min-height: 105px;
    }
    .bottom {
        align-self: flex-end;
    }
}
@media screen and (max-width: 768px) {
    .image-title {
        .image-right-title {
            min-height: 0;
        }
        .bottom {
            margin-top: 100px;
        }
    }
    .image-left {
        width: 130px !important;
        height: 73px !important;
    }
    .image-right-title{
        margin-left:10px;
    }
    .image-right-p{
        font-size:12px;
    }
}
</style>
