<template>
    <router-link :to="{path:path,query:{id:item.id,type:item.type}}" :class="['pt'+gap,'pb'+gap]" class="text-item-box flex y-top">
        <!-- 三种样式，指南-蓝(item-status-b)  解读-黄(item-status-y) 翻译绿(item-status-g) -->
        <!-- 列表前为字体 -->
        <div v-if="type == 2" class="common-status fs12" :class="[typeClass[item.class_type]]">{{item.typeName}}</div>
        <div class="flex-item t-l item-right-info" :class="{'circle':type == 1,'pl10':type != 3}">
            <div class="fs16 color333" :title="item.news_title" :class="[source == 'index' || source == 'layoutRight' ? 'row-2' : 'row-1',source == 'index' ? 'title-height':'']">
                <template v-if="type == 3 && item.type_title">
                    <span class="subtitle-color strong">{{item.type_title}}</span>
                    <span class="pl5 color999 pr5">|</span>
                </template>
                {{item.news_title}}
            </div>
            <div class="fs14 color999 flex" v-if="showDesc">
                <span>{{item.create_time}}</span>
                <span class="pl10 item-info-right row-1">{{item.source}}</span>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "text-item",
    props: {
        path:{
            type:String,
            default:''
        },
        showDesc:{
            type:Boolean,
            default:true
        },
        source:{
            type:String,
            default:''
        },
        item: {
            type: Object,
            defalut: () => {
                //   id:1,
                //   class_type:1, // 对应徽章的类型 颜色
                //   typeName:'指南',  // 对应徽章的文字
                //   title:'',  
                //   date:'',
                //   desc:'',
            }
        },
        // 1 代表显示圆点  2 代表显示对象的徽章 3 有typeName 没有徽章样式
        type: {
            type: [String, Number],
            default: 1,
        },
        // 第一个与第二个的缝隙
        gap: {
            type: [String, Number],
            default: 20,
        },
        specialType:{
            type:String,
            default:''
        }
    },
    data() {
        return {
            typeClass: ['item-status-b', 'item-status-y', 'item-status-g']
        }
    },
    created(){
    },
};
</script>

<style scoped lang="less">
.text-item-box {
    &:nth-of-type(1) {
        padding-top: 0;
    }
    &:not(:last-child) {
        border-bottom: 1px dashed #d8d8d8;
    }
    .item-right-info {
        transition: all 0.3s linear;
        position: relative;
        &.circle::before {
            content: "";
            position: absolute;
            left: -3px;
            top: 30%;
            transform: translateY(-50%);
            width: 4px;
            height: 4px;
            background-color: #ccc;
            border-radius: 100%;
        }
        &:hover {
            div {
                color: @theme;
            }
        }
        .title-height{
            height: 48px;
        }
    }
    .common-status {
        border-radius: 14px;
        color: #fff;
        padding: 3px 12px;
    }
    .item-status-b {
        background-color: @theme;
    }
    .item-status-y {
        background-color: #ffa45d;
    }
    .item-status-g {
        background-color: #1eabc9;
    }
    .item-info-right {
        width: calc(100% - 80px);
        box-sizing: border-box;
    }
}
</style>
